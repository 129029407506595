.homePage-container {
  height: 100vh;
  overflow: hidden;
}
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}

.modalContent {
  background-color: #fefefe;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 800px;
  text-align: center;
}
.modal .tab-separator {
  margin: 6px 0px;
  color: #c3c3cb;

  @media (max-width: 768px) {
    margin: 16px 0px;
  }
}
.modal .motif-tab-navigation .motif-tab-button.motif-active {
  --tab-navigation--color: #0f69ae !important;
  font-weight: 400 !important;
}
.disclaimer-container {
  background-color: #f3f3f5;
  padding: 10px;
  border-radius: 5px;
  margin-top: 20px;
  text-align: left;
}

.disclaimer {
  font-weight: bold;
}
.acknowledgeButton {
  margin-top: 20px;
}
button.acknowledgeButton {
  background-color: #197335 !important;
}

///Landing Page Style

.landingPage-home-section {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: calc(100vh - 56px);
  @media (max-width: 1024px) {
    flex-direction: column;
  }
}
.version-number {
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 10px;
}

.landingPage-img-section {
  width: 60%;

  @media (max-width: 1024px) {
    width: 100%;
    height: 40%;
  }
}
.landingPage-background-img {
  width: 100%;
}

.landingPage-text-section {
  width: 40%;

  position: relative;
  @media (max-width: 1024px) {
    width: 100%;
    height: 50%;
  }
}

.landingPage-text-section-body img {
  width: 15%;
  height: 15%;
}

.landingPage-text-section-body {
  width: 60%;

  position: absolute;
  top: 15%;
  left: 25%;

  @media (max-width: 1024px) {
    width: 48%;
    left: 20%;
  }

  @media (max-width: 768px) {
    width: 80%;
    left: 12%;
  }
}

.landingPage-nextButton {
  margin-top: 40px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  margin-right: 30px;
}

button.landingPage-nextButton {
  background-color: rgba(46, 46, 56, 1) !important;
  width: 100%;
}

.landingPage-text-section-body h4 {
  margin-top: 1px !important;
}

.landingPage-text-section-footer {
  width: 50%;
  position: absolute;
  bottom: 30%;
  left: 30%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-icons {
  background-color: white;
  border-style: none;
  color: black;
  font-weight: 200;
  text-decoration: none;
}

.motif-h4-default-bold {
  @media (max-width: 768px) {
    font-size: 1.5rem;
    font-weight: 600;
  }
}
.modal .motif-tab-navigation-horizontal .motif-tab-button.motif-active {
  --tab-navigation-horizontal-button--border-color: #fff !important;
}
