.info-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.info-modal {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 50%;
    max-width: 600px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.info-modal-header {
    position: relative;
}

.info-modal-top-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
}

.kba-code {
    font-size: 14px;
    color: #333;
}

.info-modal-lang {
    display: flex;
    align-items: center;
}

.language-code {
    margin-right: 5px;
    font-size: 14px;
    color: #333;
}

.info-modal-title {
    font-size: 24px;
    font-weight: bold;
    margin: 10px 0 20px;
}

.info-modal-content {
    max-height: 300px;
    overflow-y: auto;
    line-height: 1.5;
}

.info-cancel-button {
    background: none;
    border: none;
    cursor: pointer;
    position: absolute;
    right: -14px;
    top: -14px;
}

.globe-icon {
    cursor: pointer;
    margin-top: 10px;
}

.globe-icon svg {
    margin-top: 7px;
}