.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .error-modal {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 25%;
    max-width: 25%;
  }
  
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    border-bottom: thin solid #e8e8e8;
  }
  
  .close-button {
    background: none;
    border: none;
    font-size: 2.2rem;
    cursor: pointer;
    color: #000 !important;
  }
  
  .modal-body {
    margin-bottom: 20px;
  }
  
  .modal-footer {
    display: flex;
    justify-content: flex-end;
  }
  
  .button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.2s;
  }
  
  .cancel-button {
    background: #f3f3f5;
    margin-right: 10px;
  }
  
  .cancel-button:hover,
  .close-button:hover {
    color: #000 !important;
  }
  
  .done-button {
    background: #2e2e38;
    color: #fff;
    font-weight: 400;
  }
  
  .delete-modal-button:hover {
    background: red;
    color: #fff;
    font-weight: 400;
  }
  