@use "@ey-xd/motif-core/src/styles/themes/design-patterns/common/fonts.css"; // (Optional) Import fonts
@forward "@ey-xd/motif-core/src/styles"; // Import the core styles for design system
@forward "@ey-xd/motif-react/styles"; // Import the motif-react styles

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(211, 211, 211, 0.8);
  z-index: 999;
}
