.subheader-button-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.subheader-button-add {
  background-color: black;
  color: white;
  --btn--border-color: none;
  height: 3.5em;
}

.subheader-button {
  padding: 10px;
}

.subheader-buttons {
  display: flex;
  height: min-content;
}

.motif-text-button {
  background-color: #f2f3f4;
}

#subHeader {
  display: flex;
  justify-content: space-between;
  padding: 1% 2%;
  flex-wrap: wrap;
  gap: 10px;
}

.bordered-dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-trigger {
  padding: 10px 20px;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  color: #333;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.arrow {
  margin-left: 10px;
  font-size: 14px;
}

.dropdown-options {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  list-style: none;
  padding: 0;
  margin: 0;
}

.option {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.option:hover {
  background-color: #f0f0f0;
}

.motif-form-field {
  margin-bottom: 0 !important;
}

.option-text {
  font: bold;
}

@media (max-width: 768px) {
  #subHeader {
    flex-direction: column;
    align-items: flex-start;
  }

  .subheader-button-container {
    width: 100%;
    justify-content: space-between;
  }

  .subheader-buttons {
    width: 100%;
    justify-content: flex-end;
  }

  .subheader-button-add {
    width: 100%;
    text-align: center;
  }

  .subheader-button {
    width: 100%;
  }

  .motif-text-button {
    width: 100%;
  }
}
